import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { withTranslation } from 'language/i18n';
import {
  CallTo, MailTo, TextInput, Select, Checkbox, Form, PhoneInput,
} from 'components/common';

const ContactInformation = ({ t, classNameForBG }) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [learnedFrom, setLearnedFrom] = useState('');
  const [agreement, setAgreement] = useState(false);

  const onFormSubmit = () => {

  };

  return (
    <section className={classNameForBG}>
      <div className="container">
        <div className="row">
          <div className="col-15 col-sm-7">
            <div className="row mb-4">
              <div className="col">
                <h2 className="h3">{t('contactUs')}</h2>
                <p>{t('homeContactUsExplanation')}</p>
                <p>
                  Sıkça sorulan sorulara
                  {' '}
                  <Link href="/faq/buyers">
                    <a>
                      buradan
                    </a>
                  </Link>
                  {' '}
                  ulaşabilirsiniz.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="im-box im-box--border im-box--transparent">
                  <h3 className="h3">{t('customerService')}</h3>
                  <p className="fs-15">
                    Çağrı Merkezimize her gün 09.00 - 18.00 saatleri arasında ulaşabilirsiniz.
                  </p>
                  <div className="fs-20 d-flex align-items-center link-hover-black" href="tel:902322290625">
                    <i className="far fa-phone fs-24 mr-3" />
                    <CallTo className="fs-17 fw-900" tel="+90 850 885 46 25" />
                  </div>
                </div>
                <div className="im-box im-box--border im-box--transparent mt-3 mb-3 mb-sm-0">
                  <h3 className="h3">E-posta Adresimiz</h3>
                  <p className="fs-15">
                    Her sorunuz ve bilgi almak için email ile bize ulaşabilirsiniz.
                  </p>
                  <div className="fs-20 d-flex align-items-center link-hover-black" href="mailto:info@iyimakina.com">
                    <i className="far fa-envelope fs-24 mr-3" />
                    <MailTo className="fs-15 fw-900" mail="info@iyimakina.com" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-15 col-sm-8 col-lg-7 offset-lg-1">
            <div className="im-box im-box--shadow im-box--white im-box--padding-45">
              <Form layout="vertical" onFinish={onFormSubmit}>
                <div className="text-center">
                  <h2 className="h4">SİZİ ARAYALIM</h2>
                </div>
                <div className="row">
                  <div className="col-15 col-sm-8 mb-3 mb-sm-0">
                    <TextInput
                      name="name"
                      placeholder={t('name')}
                      value={name}
                      onChange={({ value }) => setName(value)}
                    />
                  </div>
                  <div className="col-15 col-sm-7">
                    <TextInput
                      name="surname"
                      placeholder={t('surname')}
                      value={surname}
                      onChange={({ value }) => setSurname(value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <TextInput
                      name="company"
                      placeholder={t('company')}
                      value={company}
                      onChange={({ value }) => setCompany(value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <TextInput
                      name="email"
                      placeholder={t('email')}
                      value={email}
                      onChange={({ value }) => setEmail(value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <PhoneInput
                      name="phone"
                      label={t('phoneNumber')}
                      value={phone}
                      onChange={({ value }) => setPhone(value)}
                      required
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <Select
                      name="learnedFrom"
                      placeholder="Bize Ulaşma Nedeniniz"
                      onChange={({ value }) => setLearnedFrom(value)}
                      value={learnedFrom}
                      options={[
                        { value: 'Satış İşlemleri', text: 'Satış İşlemleri' },
                        { value: 'Makina Alım İşlemleri', text: 'Makina Alım İşlemleri' },
                        { value: 'Öneri', text: 'Öneri' },
                        { value: 'Talep', text: 'Talep' },
                        { value: 'Şikayet', text: 'Şikayet' },
                        { value: 'Diğer', text: 'Diğer' },
                      ]}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <Checkbox
                      name="agreement"
                      value={agreement}
                      onChange={({ value }) => setAgreement(value)}
                      text="iyiMakina.com tarafından ileti gönderilmesini, bilgilerimin bu amaçla saklanmasını, kampanya ve tekliflerden yararlanmak için E-Posta, SMS vb. iletileri ve aramaları almayı,gönderime ilişkin hizmet alınan taraflarla paylaşılmasını haklarım saklı kalmak kaydı ile kabul ediyorum."
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button
                      type="submit"
                      className="im-button im-button--primary im-button--fluid im-button--center"
                    >
                      {t('send')}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactInformation.propTypes = {
  t: PropTypes.func,
  classNameForBG: PropTypes.string,
};

export default withTranslation('common')(ContactInformation);
